<template>
	<div class="mx-5">
		<Loader v-if="usersLoad" />
		<!-- This example requires Tailwind CSS v2.0+ -->
		<div v-else class="container flex flex-col m-auto">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
					<h3 class="mt-10 text-lg font-medium text-gray-900 leading-6">
						{{ $i18n("Users") }} {{ isOrganizationUsers ? ' - ' + $i18n("Organization") + ' - ' + organizationName : '' }}
					</h3>
					<router-link
						:to="{ name: 'User', params: { method: 'create', organizationId } }"
						class="inline-flex items-center justify-center inline-block px-4 py-2 my-5 text-base font-medium text-white bg-green-600 border border-transparent whitespace-nowrap rounded-md shadow-sm hover:bg-green-700"
					>
						{{ $i18n("Create") }}
					</router-link>
					<div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
						<table class="min-w-full divide-y divide-gray-200">
							<thead class="bg-gray-50">
								<tr>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Email") }}
									</th>
									<th
										v-if="isSuperAdmin"
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Organization") }}
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Active") }}
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Role") }}
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Feeds count") }}
									</th>
									<th scope="col" class="relative px-6 py-3"></th>
								</tr>
							</thead>
							<tbody class="bg-white divide-y divide-gray-200">
								<tr
									v-for="{ id, email, organization, active, role, feeds_count } in users"
									:key="`${id}_${active}`"
								>
									<td class="py-4 pr-6 whitespace-nowrap">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm text-gray-500">
													{{ email }}
												</div>
											</div>
										</div>
									</td>
									<td v-if="isSuperAdmin" class="py-4 pr-6 whitespace-nowrap">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm text-gray-500">
													{{ organization.name }}
												</div>
											</div>
										</div>
									</td>
									<td class="py-4 pr-6 whitespace-nowrap">
										<t-toggle
											v-if="!isCurrentUser(id) && hasPriorityOver({ id, role })"
											:checked="active"
											@change="
												(active) => {
													updateStatus(id, active);
												}
											"
											class="mr-5"
										/>
										<template v-else>
											<span
												v-if="active"
												class="inline-flex px-2 text-xs font-semibold text-green-800 bg-green-100 rounded-full leading-5"
											>
												Active
											</span>
											<span
												v-else-if="!active"
												class="inline-flex px-2 text-xs font-semibold text-green-800 bg-orange-100 rounded-full leading-5"
											>
												Not Active
											</span>
										</template>
									</td>
									<td class="py-4 pr-6 text-sm text-gray-500 whitespace-nowrap">
										{{ $i18n(role) }}
									</td>
									<td class="py-4 pr-6 text-sm text-gray-500 whitespace-nowrap">
										<router-link
											:to="{ name: 'Feeds', params: { userId: id } }"
											class="mr-5 text-blue-600 underline hover:text-blue-900"
										>
											{{ feeds_count }}
										</router-link>
									</td>
									<td class="py-4 pr-6 text-sm font-medium text-right whitespace-nowrap">
										<router-link
											v-if="!isCurrentUser(id) && hasPriorityOver({ id, role })"
											:to="{ path: `/user/update/${id}` }"
											class="mr-5 text-blue-600 hover:text-blue-900"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="inline w-6 h-6"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
												/>
											</svg>
										</router-link>
										<a
											href="#"
											v-if="!isCurrentUser(id) && hasPriorityOver({ role, id })"
											@click="deleteUserByIdMethod({ id, email })"
											class="text-red-600 hover:text-red-900"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="inline w-6 h-6"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
												/>
											</svg>
										</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
	name: "Users",
	components: {
		Loader,
	},
	data () {
		return {
			organizationName: ""
		}
	},
	watch: {
		organizationId: {
			immediate: false,
			async handler (organizationId) {
				try {
					if (!organizationId) {
						await this.fetch();
						this.$forceUpdate();
					}
				} catch (error) {
					console.debug(error);
				}
			}
		}
	},
	computed: {
		...mapGetters("users", ["users", "currentUserProfile", "usersLoad"]),
		organizationId () {
			const {
				$route: {
					params: {
						organizationId
					}
				}
			} = this;
			return organizationId
		},
		isOrganizationUsers () {
			try {
				const { organizationId } = this;
				if (organizationId) {
					return true
				}
			} catch (error) {
				console.debug(error);
			}
			return false
		},
		isSuperAdmin () {
			try {
				const {
					currentUserProfile: { role },
				} = this;
				return role === "superadmin";
			} catch (error) {
				console.debug(error);
			}
			return false;
		}
	},
	methods: {
		...mapActions("users", ["fetchUsers", "fetchOrganizationUsers", "updateUserById", "deleteUserById"]),
		...mapActions("organizations", ["fetchOrganizationById"]),
		isCurrentUser(userId) {
			try {
				const {
					currentUserProfile: { id },
				} = this;
				return id === userId;
			} catch (error) {
				console.debug(error);
			}
		},
		hasPriorityOver (user) {
			try {
				const {
					currentUserProfile: {
						role
					}
				} = this;
				const priority = {
					user: 0,
					admin: 1,
					superadmin: 2
				};
				const currentUserPriority = priority[role];
				const userPriority = priority[user.role];
				return currentUserPriority >= userPriority;
			} catch (error) {
				console.debug(error);
			}
		},
		async fetch() {
			try {
				const { organizationId } = this
				if (organizationId) {
					await this.fetchOrganizationUsers(organizationId);
				} else {
					await this.fetchUsers();
				}
			} catch (error) {
				console.debug(error);
			}
		},
		async deleteUserByIdMethod({ id, email }) {
			try {
				const {
					$dialog: { confirm },
					$i18n,
					fetch,
				} = this;

				const { isOk } = await confirm({
					clickToClose: false,
					escToClose: false,
					title: $i18n("Delete") + " " + email + " ?",
					icon: "warning",
					cancelButtonText: $i18n("Cancel"),
					okButtonText: $i18n("Do"),
				});
				if (isOk) {
					await this.deleteUserById({ id, email });
					await fetch();
				}
			} catch (error) {
				console.debug(error);
			}
		},
		async updateStatus(id, active) {
			try {
				const { isCurrentUser } = this;
				if (!isCurrentUser(id)) {
					await this.updateUserById({
						userId: id,
						payload: {
							active,
						},
					});
				}
			} catch (error) {
				console.debug(error);
			}
		},
	},
	async created() {
		try {
			await this.fetch();
		} catch (error) {
			console.debug(error);
		}
		try {
			this.organizationName = ""
			const { isOrganizationUsers } = this
			if (isOrganizationUsers) {
				const { organizationId } = this
				const { name } = await this.fetchOrganizationById(organizationId);
				this.organizationName = name
			}
		} catch (error) {
			console.debug(error);
		}
	},
};
</script>
